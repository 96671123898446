<template>
  <div id="order-view-actions-spec-paid">
    <OrderViewActionClaim
      v-if="!isQuoteOwner && (!quoteSelected.quote.claim || (quoteSelected.quote.claim && !quoteSelected.quote.claim.closed))"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionsSpecPaid',

  components: {
    OrderViewActionClaim: () => import('@/views/order/OrderViewActionClaim.vue'),
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
  },
}
</script>
